.wrapper {
	padding-top: 10px;
	display: flex;
	flex-direction: column;
}

.choices {
	max-height: 180px;
	overflow-y: auto;
	overflow-x: hidden;
}

.title {
	margin-bottom: 12px;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 143%;

	color: #242533;
}

.btns {
	margin-top: 15px;

	justify-self: flex-end;
	display: flex;
	justify-content: flex-end;
}
