.container {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 100%;
}

.field {
	border: 1px solid #d2d4e1;
	border-radius: 5px;
	background-color: white;
	padding: 10px 13px;
	color: #2f2f2f;
	font-size: 14px;
}

.labelContainer {
	display: flex;
}

.label {
	margin-bottom: 7px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #999aa3;
}

.requiredMark {
	color: #644ded;
	font-size: 14px;
	margin-right: 2px;
}
