.bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
}

.progress {
    flex: 1;
    height: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #EEEEF2;
    border-radius: 30px;
}