.root {
	border-bottom: 1px solid #eeeef2;
	height: 50px;

	display: grid;
	grid-template-columns: 1fr 83px;
	align-items: center;

	&:last-child {
		border-bottom: none;
	}
}

.text {
	margin-right: 10px;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 114%;

	color: #242533;
}

.input {
	flex: 1;
	input {
		width: 100%;
	}
}

.left {
	display: flex;
	align-items: center;
}

.right {
	display: flex;
	align-items: center;

	justify-self: flex-end;
}

.link {
	margin-right: 10px;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 123%;

	text-decoration-line: underline;
	color: #644ded;
}
