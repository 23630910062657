.textarea {
	padding: 12px 16px;

	font-size: 13px;
	font-weight: 400 !important;
	line-height: 16px;
	color: #242533;
	background: #f3f3f8;
	border: 1px solid #f3f3f8;
	border-radius: 8px;

	resize: none;

	&::placeholder {
		font-family: 'Poppins';
		font-weight: 400 !important;
		color: #808192;
		font-size: 13px;
	}

	&:focus {
		outline: none;
		border: 1px solid #644ded;
		background: #fff;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px var(--color--white) inset !important;
		box-shadow: 0 0 0 30px var(--color--white) inset !important;
	}
	&::-webkit-scrollbar-track {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.error {
	border: 1px solid #ff218c;
}

.textWrapper {
	padding: 12px 16px;
	background: #f3f3f8;
	border-radius: 8px;
	border: 1px solid #f3f3f8;
	overflow: hidden;
}
.text{

	font-size: 13px;
	font-weight: 400 !important;
	line-height: 16px;
	color: #242533;
	overflow-y: auto;
	margin-right: -12px;

	&::-webkit-scrollbar-track {
		margin-top: -10px;
		margin-bottom: -10px;
	}
}

.link {
	color: #644DED;
	text-decoration: underline;
}