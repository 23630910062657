.root {
	display: flex;
	flex-direction: column;
}

.detail {
	width: 100%;

	height: 50px;

	border-bottom: 1px solid #eeeef2;
	display: flex;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&:last-child {
		border: none;
	}
}

.text {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;

	font-size: 14px;
	line-height: 114%;
	color: #242533;
}
