.table {
	flex: 1;
	height: 100%;

	display: flex;
	flex-direction: column;
}

.tooltip {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 18px;
}

.filters {
	display: flex;
	gap: 26px;
}

.assets {
	height: calc(100vh - 272px);
	overflow: auto;

	display: grid;
	grid-template-columns: repeat(4, 1fr);

	column-gap: 30px;
	row-gap: 40px;
}
