html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
}

body {
	background: var(--color--app-bg);

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: var(--color-light-theme);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #e5e2e7;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #e5e2e7;
	}
}

html,
body,
#root {
	width: 100%;
	height: 100%;

	overflow: hidden;
}

img {
	object-fit: contain;
}

button {
	cursor: pointer;
	border: none;
}

// #popper[data-popper-reference-hidden='true'] {
// 	opacity: 0;
// 	pointer-events: none;
// }
