.prism-token.token.name {
	color: #0167ec;
}

.prism-token.token.choice {
	color: #009f9c;
}
.prism-token.token.command {
	color: #ff218c;
}
.prism-token.token.reaction {
	color: #019822;
}

.editor-name-token {
	color: #0167ec;
}
.editor-command-token {
	color: #009f9c;
}
.editor-choice-token {
	color: #ff218c;
}
.editor-method-token {
	color: #644ded;
}
.editor-reaction-token {
	color: #019822;
}
.editor-search-token {
	background-color: #a2d9b1 !important;
}

.editor-current-token {
	background-color: #64bd74 !important;
}
span.editor-default {
	color: #1a202c !important;
}

.DraftEditor-root {
	font-size: 14px;
	line-height: 24px;

	counter-reset: my-sec-counter;
}

.DraftEditor-editorContainer {
	height: calc(var(--vh, 1vh) * 100 - 65px - 50px - 24px);
	padding-top: 2px;
	overflow: auto;
}

.public-DraftStyleDefault-block {
	margin-left: 60px;
}

.public-DraftStyleDefault-block::before {
	position: absolute;
	left: -60px;
	top: 0;
	counter-increment: my-sec-counter;
	content: '' counter(my-sec-counter) '';
	display: inline-block;
	width: 60px;
	text-align: center;
	color: #bcbcc6;
}

.error-block,
.error-block.line-active {
	background-color: #ffe7e7;
}

.error-block .public-DraftStyleDefault-block::before {
	color: #ed0000;
}

.error-block.line-active .public-DraftStyleDefault-block::after {
	border-left-color: #ed0000;
}

.warning-block,
.warning-block.line-active {
	background-color: #fff5e7;
}

.warning-block .public-DraftStyleDefault-block::before {
	color: #f49304;
}

.warning-block.line-active .public-DraftStyleDefault-block::after {
	border-left-color: #f49304;
}

.line-active {
	position: relative;
	background-color: #f4f4f8;
}
.line-active-default {
	position: relative;
	background-color: #f4f4f8;
}
.line-active .public-DraftStyleDefault-block::before {
	color: #242533;
}

.line-active .public-DraftStyleDefault-block::after {
	content: '';
	position: absolute;
	left: -60px;
	top: 0;
	height: 100%;
	border-left: 4px solid #644ded;
	border-radius: 2px;
}

/* .line-active .public-DraftStyleDefault-block::after {
	color: #242533 !important;
} */
.hide {
	height: 0.01px;
	overflow: hidden;
}

.arrow-close {
	width: 20px;
	padding: 0 10px;
	margin-right: -30px;
}

div[data-dialogue] {
	position: relative;
}

div[data-dialogue].line-active::after {
	color: #242533;
}

div[data-dialogue]::after {
	content: attr(data-dialogue);
	position: absolute;
	top: 4px;
	right: 10px;
	font-size: 12px;
	line-height: 18px;
	color: #bcbcc6;
}
