.root {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    
    color: #242533;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    
    color: #484964;
}