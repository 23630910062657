.container {
	padding: 10px 12px;
	margin-bottom: 10px;

	display: grid;
	grid-template-columns: 37px 1fr;
	grid-column-gap: 10px;
	align-items: center;
}

.link {
	height: 38px;
}
.logo {
	width: 100%;
}

.title {
	font-family: Nunito;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 110%;
	color: #242533;

	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.open {
	opacity: 1;
}
