.wrapper {
	display: flex;
	flex-direction: column;

	padding: 20px 0 17px;
	border-bottom: 1px solid #eeeef2;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-bottom: 17px;
}

.title {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;

	color: #242533;
}

.keywords {
	display: flex;
	gap: 7px;
}

.place {
	height: 23px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}
