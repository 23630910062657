.title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 114%;
	color: #242533;
}

.wrapper {
	position: relative;
	padding: 13px 0;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.extra {
	padding-bottom: 15px;
	border-top: 1px solid #eeeef2;
}

.tags {
	display: flex;
	flex-wrap: wrap;

	gap: 10px;
}
