:root {
	--color-light-theme: #fbfbfc;
	--color-yellow: #ffefc7;
	--color-green: #28b046;
	--color-green-light: #e1f5e5;
	--color-blue-light: #deedff;
	--color-dataviz-pink-100: #ff0066;
	--color-dataviz-violet-100: #8012ff;
	--color-dataviz-celestial-blue-100: #00bdb9;
	--color-dataviz-green-100: #07ce33;
	--color-dataviz-blue-100: #006fff;
	--color-dataviz-orange-100: #f49304;
	--color-red-02-100: #ed0000;
	--color-dark-tone-80: #484964;
	--color-dark-tone-60: #808192;
	--color-dark-tone-100: #242533;
}
