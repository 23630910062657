.container {
	display: flex;
	flex-direction: column;
}

.form {
	margin-top: 10px;
	margin-bottom: 16px;
}

.btns {
	margin-top: 10px;

	display: flex;
	justify-content: flex-end;
}
