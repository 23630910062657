.item {
	position: relative;
	cursor: pointer;

	display: flex;
	padding: 6px 8px 6px 0;

	align-items: center;

	margin-bottom: 3px;
}

.selectedItem {
	.label {
		color: #644ded;
	}

	.labelIcon {
		fill: #644ded;
	}
}

.selectedSingle {
	background: rgba(93, 66, 255, 0.08);
	border-radius: 6px;
}

.singleChoiceItem {
	width: 100%;
	padding-left: 8px;

	&:hover {
		background: rgba(93, 66, 255, 0.08);
		border-radius: 6px;

		.label {
			color: #644ded;
		}
	}
	.label {
		padding: 8px 9px;
	}
}

.multiChoiceItem {
	width: calc(100% - 18px);

	.label {
		padding: 9px 0 9px 9px;
	}
}

.label {
	display: flex;
	align-items: center;
	gap: 4px;

	width: 100%;

	font-weight: 500;
	font-size: 13px;
	line-height: 16px;

	&Icon {
		height: 16px;
		width: 16px;
		margin-left: 4px;
		fill: #484964;
	}
}
