.category {
	width: fit-content;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 99px;

	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;

	color: #242533;
	cursor: pointer;
}

.selectedCategory {
	background: rgba(100, 77, 237, 0.08);
}
