.wrapper {
	margin-bottom: 25px;
	margin-top: 20px;

	display: grid;
	padding: 19px 20px;
	grid-template-columns: 22px 1fr;
	grid-column-gap: 9px;

	border-radius: 0 16px 16px 0;
	cursor: pointer;

	align-items: center;
	&:hover {
		background: rgba(100, 77, 237, 0.08);
		color: #644ded;
		.text {
			color: #644ded;
		}
	}
}

.text {
	color: #484964;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;

	white-space: nowrap;

	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.icon {
	width: 100%;
}

.open {
	opacity: 1;
}

.openedSidebar {
	transform: rotate(180deg);
	transition: transform 0.3s ease-in-out;
}

.closedSidebar {
	transform: rotate(0);
	transition: transform 0.3s ease-in-out;
}
