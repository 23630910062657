.counter {
	color: #808192;
	display: inline-block;
	min-width: 50px;
	border-radius: 10px;
	padding: 2px;
	text-align: center;
	margin-bottom: 5px;
}

.counterOverLimit {
	color: tomato;
	display: inline-block;
	min-width: 50px;
	border-radius: 10px;
	padding: 2px;
	text-align: center;
	margin-bottom: 5px;
}

.counterWord {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;

	font-size: 14px;
	color: #242533;

	margin: 0 0 0 5px;
	padding: 0;

	min-width: auto;
}

.counterValue {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;

	font-size: 14px;
	color: #808192;

	margin: 0 0 0 5px;
	padding: 0;

	min-width: auto;
}

.counterMax {
	font-size: 14px;

	color: #242533;
	font-weight: 600;
}

.textWidth {
	opacity: 0;
}

.spacer {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	border-right: 1px solid #eeeef2;
}

.actionSection {
	position: relative;

	padding: 0 19px;
	border-right: 1px solid #eeeef2;
	height: 100%;
	min-width: 50px;
	width: fit-content;

	display: flex;
	align-items: center;
	justify-content: center;
}
