.multipleList {
	width: 100%;
	height: 100%;
	overflow: auto;

	display: flex;
	flex-direction: column;
	gap: 5px;
}

.keywordsList {
	margin: 10px 0 4px;
}

.selected {
	min-height: 34px;
	height: 34px;
	overflow-y: scroll;
	display: flex;
	gap: 5px;

	margin: 10px 0 4px;
	padding-bottom: 4px;
}
