.container {
	max-width: 100vw;
	max-height: 100vh;

	width: 100%;
	height: 100%;

	overflow: hidden;

	display: grid;
	grid-template-columns: auto 1fr;
}
