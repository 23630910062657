.colorsContainer {
	padding: 16px 14px 5px;
}

.title {
	display: block;
	margin-bottom: 7px;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	color: #242533;
}

.optionsContainer {
	width: 240px;
	display: flex;
	flex-wrap: wrap;
}

.option {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99999px;
	width: 20px;
	height: 20px;
	margin: 2px;

	background-color: transparent;

	&:hover {
		border: 1px solid #644ded;
	}
}

.color {
	width: 14px;
	height: 14px;
	min-width: 14px;
	min-height: 14px;
	max-width: 14px;
	max-height: 14px;

	border-radius: 999999px;
}

.colorPicker {
	margin: 11px;
	padding: 10px 20px 10px 12px;
	font-size: 13px;
	line-height: 16px;
}

.colorBorder {
	border: 1px solid #f3f3f3;
}

.divider {
	height: 1px;
	width: 100%;
	background: #eeeef2;
}

.colorlessImage {
	width: 24px;
	height: 24px;
	margin-right: 5px;
}
