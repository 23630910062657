.container {
	position: relative;
	display: flex;
	flex-direction: column;
}

.labelContainer {
	display: flex;
}

.label {
	font-weight: 500;
	margin-bottom: 7px;
	font-size: 14px;
	line-height: 21px;
	color: #999aa3;
}

.requiredMark {
	color: #644ded;
	font-size: 14px;
	margin-right: 2px;
}
