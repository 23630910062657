:root {
	--color--white: #ffffff;
	--color--main-black: #2f2f2f;
	--color--app-bg: #fbfbfc;
	--color--gray: #f8f7fd;
	--color--main-pink: #f4447b;
	--color--main-gray: #d2d4e1;
	--color--dark-gray: #767676;
	--color--red: #ea4335;
}
