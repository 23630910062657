.wrapper {
	display: flex;
	flex-direction: column;

	padding-bottom: 19px;
	border-bottom: 1px solid #eeeef2;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bottom {
	padding-bottom: 17px;
}

.top {
	padding-top: 17px;
}

.title {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;

	color: #242533;
}

.place {
	height: 23px;
}

.error {
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	color: #ff218c;
}

.action {
	display: flex;
	align-items: center;
}
