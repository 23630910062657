.root {
	height: 100%;
	width: 260px;

	background: var(--color-light-theme);

	border-radius: 16px 0 0 16px;
	border-right: 1px solid #eeeef2;
}

.header {
	height: 76px;

	display: flex;
	align-items: center;
}

.btn {
	background-color: transparent;
	width: 100%;
	height: 100%;
	padding: 0 21px;
}

.dropdown {
	margin-top: -10px;
	margin-left: 10px;
}

.navItem {
	margin-right: 43px;
	padding: 19px 21px;

	cursor: pointer;

	border-radius: 0 16px 16px 0;
	display: flex;
	align-items: center;

	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
}

.activeNavItem {
	color: #644ded;
	background: rgba(100, 77, 237, 0.08);
}