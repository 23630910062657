.root {
	position: absolute;
	z-index: 1000;
	display: flex;

	width: 100%;
	height: 65px;
	border-bottom: 1px solid #eeeef2;
}

.logoContainer {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	min-width: 60px;
	max-width: 60px;
	border-right: 1px solid #eeeef2;
}

.logoImage {
	width: 38px;
	height: 38px;
}

.bookInfo {
	display: flex;
	align-items: center;
}

.updateStatus {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 5px 0 0 15px;
}

.syncImage {
	padding-right: 4px;
}

.syncText {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;

	color: #808192;
}

.controlsBar {
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.storyInfo {
	display: flex;
	align-items: center;

	height: 100%;
	padding-left: 20px;
}

.storyName {
	margin: 0;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

	color: #242533;

	margin-bottom: -6px;
}

.storySelect {
	display: flex;
	justify-content: center;
	align-items: center;
}

.storyActions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
}

.storyProp {
	display: flex;
	align-items: center;
	gap: 20px;

	color: #484964;
}

.storySave {
	display: flex;
	align-items: center;
	gap: 25px;
}

.storySync {
	height: 16px;
	display: flex;
	align-items: center;

	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;

	color: #484964;
}

.editButtonContainer {
	width: 60px;
	min-width: 60px;
	max-width: 60px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editorSyncStatus {
	margin: 5px 0 0 10px;
}

.divider {
	margin: 0 5px;
	width: 1px;
	height: 23px;

	background-color: #eeeef2;
}
