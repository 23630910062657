.root {
	border-bottom: 1px solid #eeeef2;
	padding: 10px 0px 10px;
	min-height: 51px;

	display: grid;
	grid-template-columns: 200px 1fr;
	align-items: center;
}

.borderNone {
	border-bottom: none !important;
}


.withoutBorder {
	border: none;
}

.text {
	margin-right: 10px;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 114%;

	color: #242533;
}

.left {
	display: flex;
	align-items: center;
}

.underImage {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 240px;
}

.right {
	display: flex;
	align-items: center;

	justify-self: flex-end;
}

.name {
	margin-right: 10px;
	max-width: 350px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	font-family: 'Poppins';

	color: #242533;
}

.error {
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	color: #ff218c;

	grid-column: 2;
	display: flex;
	justify-content: flex-end;

	margin: 3px 60px 0 0;
}
